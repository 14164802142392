@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300&display=swap');

body {
  font-family: "Roboto Condensed", sans-serif !important;
}

:root {
  --primary-white: #ffffff;
  --primary-light-grey: #f4f4f4;
  --primary-orange: #f9ab00;
  --primary-dark: rgb(52, 58, 54);
  --primary-red: #ef4035;
  --primary-hover-red: #f33501;
  --primary-black: #000000;
  --primary-dark-grey: #666666;
  --primary-grey: #ccd1d9;
}

/* NAVBAR */
nav {
  z-index: 2;
}

nav a.nav-link {
  letter-spacing: 0.0375rem;
  cursor: pointer
}

nav ul li {
  text-transform: uppercase;
}

.navbar-nav > li > a {
  margin-right: 0.75rem;
  margin-left: 0.75rem;
  color: var(--primary-white) !important;
  border-top: 0.1875rem solid var(--primary-dark)
}

.navbar-brand {
  max-width: 17.25rem;
  padding: 0 !important;
  height: 6rem !important;
  font-size: 400% !important
}

@media(max-width: 768px) {
  .navbar-brand {
    width: 7rem;
  }
}

.navbar-nav > li > a.active, .navbar-nav > li > a:focus {
  border-top: 0.1875rem solid var(--primary-orange);
}

.navbar-nav > li > a:hover {
  border-top: 0.1875rem solid var(--primary-orange);
}


/* HEADER */
.header-wrapper {
  position: relative;
  background: url(./img/wall-and-laptop-background.jpg) no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
}

.main-info {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 1;
}

.main-info h1 {
  color: var(--primary-orange);
  text-transform: uppercase;
  font-size: 500%;
  font-weight: 800;
}

/* FOOTER */
.footer {
  background: var(--primary-black);
  color: var(--primary-dark-grey);
  padding: 3rem;
}

.footer a {
  text-decoration: none;
  color: var(--primary-dark-grey);
}

.footer a:hover {
  text-decoration: none;
  color: var(--primary-orange);
  cursor: pointer;
}

.footer p {
  margin-bottom: 0;
}


/* TYPED TEXT */
.typed-text {
  font-size: 2rem;
  color: var(--primary-white)
}

@media(max-width: 768px) {
  .main-info h1 {
    font-size: 1.7rem !important;
  }
  .typed-text {
    font-size: 1.3rem;
  }
}


/* HEADER BUTTON */
.btn-main-offer {
  border: 1px solid var(--primary-red);
  text-transform: uppercase;
  border-radius: 0;
  padding: 0.625rem 0.75rem 0.75rem 0.75rem;
  color: var(--primary-white);
  margin: 2rem;
}

.btn-main-offer {
  text-decoration: none;
  background-color: var(--primary-hover-red);
  color: var(--primary-white);
  transition: .2s ease-in-out;
}


/* PARTICLES */
.tsparticles-canvas-el {
  position: absolute;
  height: 100vh;
  width: 100vw;
  z-index: 1;
}

@media(max-width: 768px) {
  .particles-canvas {
    display: none;
  }
}


/* ABOUT ME */
.photo-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-img {
  width: 16rem;
  border-radius: 50%;
  padding: 0.3rem;
  border: 1px solid var(--primary-orange)
}

.about-heading {
  color: var(--primary-orange);
  text-transform: uppercase;
}

@media(max-width: 468px) {
  .profile-img {
    width: 10rem;
  }
}

/* TECH STACK */
.tech-stack {
  text-align: center;
  padding-bottom: 2rem;
  background: var(--primary-light-grey)
}

.tech-stack h1 {
  color: var(--primary-dark);
  text-transform: uppercase;
}

.tech-stack .circle {
  position: relative;
  margin: 0.375rem auto;
  background: var(--primary-orange);
  border-radius: 50%;
  width: 3.125rem;
  height: 3.125rem;
}

.tech-stack .icon {
  font-size: 1.4rem;
  color: var(--primary-white);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.tech-stack .box {
  height: 16rem;
  border-bottom: 0.3125rem solid var(--primary-orange);
  padding: 0.625rem;
  margin-bottom: 1.875rem;
  background: var(--primary-white);
  transition: 0.2s ease-in-out;
}

.tech-stack .box:hover {
  background: var(--primary-orange);
  border-bottom: 0.3125rem solid var(--primary-dark)
}

.tech-stack .box:hover .icon {
  color: var(--primary-dark);
}

.tech-stack .box:hover .circle {
  background: var(--primary-white);
}

.tech-stack .box:hover p {
  color: var(--primary-white);
}

/* PORTFOLIO */
.portfolio-wrapper {
  background: var(--primary-light-white);
  padding: 3rem 0;
}

.portfolio-wrapper h1 {
  color: var(--primary-orange);
}

.portfolio-image {
  width: 15rem;
  border: 1px solid var(--primary-orange);
  padding: 0 0.5rem;
}

.portfolio-image-box {
  position: relative;
  margin: 1rem;
}

.overflow {
  position: absolute;
  opacity: 0;
  background: var(--primary-black);
  width: 15rem;
  height: 8rem;
  top: 0;
  border: 1px solid var(--primary-red);
  cursor: pointer;
}

.portfolio-image-box:hover .overflow {
  opacity: 60%;
  transition: 0.3s ease-in-out;
}

.portfolio-icon {
  position: absolute;
  opacity: 0;
  margin-left: -8rem;
  margin-top: 3.6rem;
  color: var(--primary-orange);
  font-size: 1.5rem;
}

.portfolio-image-box:hover .portfolio-icon {
  opacity: 70%;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}

.portfolio-image-popupbox {
  width: 45rem;
  padding: 0 0.5rem;
}

.hyper-link {
  cursor: pointer;
  color: var(--primary-orange);
}

.hyper-link:hover {
  color: var(--primary-red);
}

@media(max-width: 768px) {
  .portfolio-image-popupbox {
    width: 100%;
  }
}

@media(max-height: 570px) {
  .popupbox-wrapper {
    height: 100%;
  }
  .portfolio-image-popupbox {
    width: 50%;
  }
}

@media(max-height: 280px) {
  .popupbox-wrapper {
    height: 90%;
  }
  .portfolio-image-popupbox {
    width: 30%;
  }
}


/* CONTACT */
.contacts {
  /* background: var(--primary-dark); */
  padding-left: 1rem;
  padding-right: 1rem;
  padding: 3rem 0;
}

.contacts h1 {
  color: var(--primary-orange);
  padding: 1.3rem 0;
  text-transform: uppercase;
}

.contacts p {
  color: var(--primary-light-grey);
  font-size: 1.2rem;
}

#submit:hover {
  color: var(--primary-white);
  background: var(--primary-hover-red);
  border: none;
}

.contacts input, .contacts textarea {
  width: 100%;
  box-sizing: border-box;
  background: none;
  resize: none;
  border: 0;
  border-bottom: 0.125rem solid var(--primary-dark-grey);
  border-radius: 0;
  color: var(--primary-orange);
  outline: 0 !important;
  box-shadow: none !important;
  top: 0;
  margin-bottom: 1rem;
  padding-left: 0 !important;
}

.line {
  position: relative;
  top: -0.8rem;
  width: 0;
  height: 0.125rem;
  background: var(--primary-orange);
  display: inline-block;
}

input:focus+.line, textarea:focus+.line {
  width: 100%;
  transition: width 0.5s ease-in-out;
}

input.form-control {
  margin: -0.3rem 0;
}

textarea.form-control {
  margin: -0.3rem;
}

.contacts .container {
  padding: 4rem 0;
}

.error-message {
  color: var(--primary-orange);
  text-align: left;
}

.success-message {
  font-weight: 900;
  color: var(--primary-orange)
}

.contacts input:focus, .contacts textarea:focus {
  background: transparent;
  color: var(--primary-orange);
  border-bottom: 0.125rem solid var(--primary-dark-grey);
}

.contacts textarea {
  height: 12.2rem !important;
}

.contact-btn {
  margin: 1rem 0 0 0;
  width: 100%;
  background: none;
};

@media(max-width: 768px) {
  .contacts {
    overflow-x: hidden;
  };
  .contacts .container {
    padding: 0 1rem;
  };
  .contacts p {
    padding: 0 0.4rem;
  };
};